import { render, staticRenderFns } from "./CommonInfo.vue?vue&type=template&id=5d26d62f&scoped=true&"
import script from "./CommonInfo.vue?vue&type=script&lang=js&"
export * from "./CommonInfo.vue?vue&type=script&lang=js&"
import style0 from "./CommonInfo.vue?vue&type=style&index=0&id=5d26d62f&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d26d62f",
  null
  
)

export default component.exports